import React, { FC } from 'react';
import { Link } from 'gatsby';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';

import {
  PagingInfo,
  ResultsPerPage,
  Paging,
  Sorting,
  WithSearch,
} from '@elastic/react-search-ui';
import '@elastic/react-search-ui-views/lib/styles/styles.css';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      flexGrow: 1,
    },
  }),
);

const SORT_OPTIONS = [
  {
    name: 'Relevance',
    value: '',
    direction: '',
  },
  {
    name: 'Title',
    value: 'title',
    direction: 'asc',
  },
];

const Search: FC = () => {
  const classes = useStyles();
  const isBrowser = !(typeof window === 'undefined');
  return (
    <>
      {isBrowser && (
        <WithSearch
          mapContextToProps={({ wasSearched, results }: any) => ({
            wasSearched,
            results,
          })}
        >
          {({ wasSearched, results }: any) => {
            return (
              <>
                <Container className={classes.root}>
                  <div className="sui-layout">
                    <div className="sui-layout-body">
                      <div className="sui-layout-body__inner">
                        <div className="sui-layout-sidebar">
                          <div>
                            <div className="sui-sorting">
                              {wasSearched && (
                                <Sorting
                                  label={'Sort by'}
                                  sortOptions={SORT_OPTIONS}
                                />
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="sui-layout-main">
                          <div className="sui-layout-main-header">
                            <div className="sui-layout-main-header__inner">
                              {wasSearched && <PagingInfo />}
                              {wasSearched && <ResultsPerPage />}
                            </div>
                          </div>
                          <div className="sui-layout-main-body">
                            <ul className="sui-results-container">
                              {results.map((r: any) => {
                                // We only expect local URLs using the seeded base URL: https://portlandgeneral.com
                                // Remove host for use with Gatsby Link local naviagation, avoiding secondary page
                                // load on hydration.
                                const localPath = r?.url?.raw.slice(27);

                                return (
                                  <li className="sui-result" key={r.id.raw}>
                                    <div className="sui-result__header">
                                      <Link
                                        className="sui-result__title sui-result__title-link"
                                        to={localPath}
                                      >
                                        <span
                                          dangerouslySetInnerHTML={{
                                            __html: r?.title?.snippet,
                                          }}
                                        />
                                      </Link>
                                    </div>
                                    <div className="sui-result__body">
                                      <ul className="sui-result__details">
                                        <li style={{ paddingBottom: '10px' }}>
                                          <span
                                            className="sui-result__value"
                                            dangerouslySetInnerHTML={{
                                              __html:
                                                r?.body?.snippet?.length > 200
                                                  ? `${r?.body?.snippet?.substr(
                                                      0,
                                                      200,
                                                    )}...`
                                                  : r?.body?.snippet,
                                            }}
                                          />
                                        </li>
                                        <li>
                                          <span className="sui-result__value">
                                            {r.url.raw}
                                          </span>
                                        </li>
                                      </ul>
                                    </div>
                                  </li>
                                );
                              })}
                            </ul>
                          </div>
                          <div className="sui-layout-main-footer">
                            <Paging />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Container>
              </>
            );
          }}
        </WithSearch>
      )}
    </>
  );
};

export default Search;
